/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ArticleRoll from '../components/ArticleRoll';

export const IndexPageTemplate = () => (
    <>
        <SEO />
        <ArticleRoll />
    </>
);

const IndexPage = () => (
    <Layout>
        <IndexPageTemplate />
    </Layout>
);

export default IndexPage;
