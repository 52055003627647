/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import classNames from 'classnames';
import formatArticles from './formatArticles';
import FeaturedArticle from './FeaturedArticle';
import Column from './Column';

const ArticleRoll = ({ data }) => {
    const { edges } = data.allMarkdownRemark;
    if (!edges) return null;
    const [featuredArticle, columns] = formatArticles(edges);
    console.info({featuredArticle, columns})
    return (
        <>
            {featuredArticle && <FeaturedArticle article={featuredArticle} />}
            {columns && (
                <div className={classNames('pure-g', 'container')}>
                    {columns.map(column => (
                        <Column column={column} />
                    ))}
                </div>
            )}
        </>
    );
};

ArticleRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array
        })
    }).isRequired
};

export default () => (
    <StaticQuery
        query={graphql`
            query ArticleRollQuery {
                allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: { templateKey: { in: ["link", "story"] } }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 400)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                templateKey
                                title
                                url
                                date
                                featured
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 900, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data, count) => <ArticleRoll data={data} count={count} />}
    />
);
