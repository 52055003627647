/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const PreviewCompatibleImage = ({ imageInfo = {}, ...props }) => {
    const alt = imageInfo.alt || props.alt || '';
    const { childImageSharp } = imageInfo;
    const image = imageInfo.image || props.image;

    if (image && image.childImageSharp)
        return <Img fluid={image.childImageSharp.fluid} alt={alt} {...props} />;

    if (childImageSharp)
        return <Img fluid={childImageSharp.fluid} alt={alt} {...props} />;

    if (image && typeof image === 'string')
        return <img src={image} alt={alt} {...props} />;

    return null;
};

PreviewCompatibleImage.propTypes = {
    imageInfo: PropTypes.shape({
        alt: PropTypes.string,
        childImageSharp: PropTypes.object,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
            .isRequired
    }).isRequired,
    alt: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

PreviewCompatibleImage.defaultProps = {
    alt: '',
    image: ''
};

export default PreviewCompatibleImage;
