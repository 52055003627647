/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

const maxColumns = 3;

/**
 * Returns an array with arrays of the given size.
 *
 * @param articles {Array} array to split
 */
const formatArticles = articles => {
    const columns = [];
    // Extract the latest featured article
    let featuredIdx = -1;
    for (let i = 0; i < articles.length; i += 1)
        if (articles[i].node.frontmatter.featured === true) {
            featuredIdx = i;
            break;
        }
    const featuredArticle = featuredIdx !== -1 ? articles[featuredIdx] : null;
    // Split the remaining articles into `maxColumns` columns
    const columnArticles = [
        ...articles.slice(0, featuredIdx),
        ...articles.slice(featuredIdx + 1)
    ];
    for (let i = 0; i < columnArticles.length; i += maxColumns) {
        const chunk = columnArticles.slice(i, i + maxColumns);
        // Do something if you want with the group
        columns.push(chunk);
    }
    return [featuredArticle, columns];
};

export default formatArticles;
