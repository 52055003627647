/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Article from './Article';

const Column = ({ column }) => (
    <div className={classNames('pure-u-1', 'pure-u-md-1-3')}>
        {column.map(article => (
            <Article article={article} />
        ))}
    </div>
);

Column.propTypes = {
    column: PropTypes.arrayOf(
        PropTypes.shape({
            excerpt: PropTypes.string,
            fields: PropTypes.shape({ slug: PropTypes.string.isRequired }),
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                url: PropTypes.string,
                image: PropTypes.string,
                featured: PropTypes.bool
            })
        })
    ).isRequired
};

export default Column;
