/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ArticleLink = ({ children, slug, url, ...props }) => (
    <>
        {url ? (
            <a href={url} {...props}>
                {children}
            </a>
        ) : (
            <Link to={slug} {...props}>
                {children}
            </Link>
        )}
    </>
);

ArticleLink.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    slug: PropTypes.string,
    url: PropTypes.string
};

ArticleLink.defaultProps = {
    slug: '',
    url: ''
};

export default ArticleLink;
