/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../PreviewCompatibleImage';

const ArticleImage = ({ image, title }) =>
    image ? (
        <PreviewCompatibleImage image={image} alt={title} className="feature" />
    ) : null;

ArticleImage.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default ArticleImage;
