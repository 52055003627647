/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ArticleLink from './ArticleLink';
import ArticleImage from './ArticleImage';

const Article = ({
    article: {
        node: {
            excerpt,
            fields: { slug },
            frontmatter: { title, url, image, featured }
        }
    }
}) => (
    <ArticleLink slug={slug} url={url}>
        <article className="article-list-item">
            <ArticleImage image={image} title={title} />
            <h2 className={classNames('headline', featured && 'featured')}>
                {title}
            </h2>
        </article>
        {excerpt && <aside>{excerpt}</aside>}
    </ArticleLink>
);

export const propTypes = {
    article: PropTypes.shape({
        node: PropTypes.shape({
            excerpt: PropTypes.string,
            fields: PropTypes.shape({ slug: PropTypes.string.isRequired }),
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                url: PropTypes.string,
                image: PropTypes.string,
                featured: PropTypes.bool
            })
        })
    }).isRequired
};

Article.propTypes = propTypes;

export default Article;
