/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import ArticleLink from './ArticleLink';
import ArticleImage from './ArticleImage';
import { propTypes } from './Article';

const FeaturedArticle = ({ article: { node: article } }) => {
    console.info({ article });
    const {
        excerpt,
        fields: { slug },
        frontmatter: { title, url, image }
    } = article;

    return (
        <ArticleLink url={url} slug={slug} className="main-feature">
            <div className="container">
                <article>
                    <ArticleImage image={image} title={title} />
                    <h1 className="headline">{title}</h1>
                </article>
                {excerpt && <aside>{excerpt}</aside>}
            </div>
        </ArticleLink>
    );
};

FeaturedArticle.propTypes = propTypes;

export default FeaturedArticle;
